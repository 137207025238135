import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import ContentReverse from '../components/ContentReverse';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const VideoIframe = styled.div`
    position: relative;
    padding-bottom: 53%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 7% 0%;
`;

const IframeVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('wemapArPage');

    const icons = [{
        icon: 'icon-cube',
        text: t('TITLE_ICON_WEB_AR')
    }, {
        icon: 'icon-mobile1',
        text: t('TITLE_ICON_ANDROID_IOS')
    },
    {
        icon: 'icon-checkbox-checked',
        text: t('TITLE_ICON_BENEFITS')
    }];

    const right = [
        {textRight: t('TITLE_NAV')},
        {
            icon: 'icon-location-current',
            text: t('DESCRIPTION_IMMERSIVE')
        },
        {
            icon: 'icon-map5',
            text: t('DESCRIPTION_GUIDAGE')
        },
        {
            icon: 'icon-layers1',
            text: t('DESCRIPTION_ENVIRONMENT')
        }];

    const search = [
        {textRight: t('TITLE_VISUAL_COMBINE')},
        {
            icon: 'icon-search2',
            text: t('DESCRIPTION_SEARCH_IMMERSIVE')
        },
        {
            icon: 'icon-list3',
            text: t('DESCRIPTION_DATA_GUIDAGE')
        },
        {
            icon: 'icon-explore1',
            text: t('DESCRIPTION_ORIENT_ENVIRONMENT')
        }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_AUGMENTED_REALITY')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_AUGMENTED_REALITY')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_AR')}
                        cellParagraph={t('DESCRIPTION_MAP_RICH_CONTENT')}
                        videoUrl='/images/products/wemap-ar/wemap-augmented-reality.mp4'
                        pictureRight={data.whiteMobile.childImageSharp}
                        altDescription='mobile gif of Wemap augmented reality outdoor navigation'
                        titleButton={t('TITLE_BUTTON_TRY_GET_STARTED')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_SEARCH_FILTERS')
                            },
                            {
                                icon: 'icon-mobile',
                                text: t('DESCRIPTION_SERVICES')
                            },
                            {
                                icon: 'icon-location-current',
                                text: t('DESCRIPTION_AR_MAP_VIEW')
                            }
                        ]}
                    />

                    <CustomersLogos
                        title={t('THEY_USE_AR')}
                        size='large'
                        customersLogos={[
                            {image: '/images/home/logo-ministere-culture.png'},
                            {image: '/images/products/wemap-ar/hachette-tourisme.svg'},
                            {image: '/images/products/wemap-ar/logo_uh.png'},
                            {image: '/images/products/indoor/logo_ratp.svg'},
                            {image: '/images/industries/sncf-logo.png'}
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_FULL_WEB')}
                            text={t('DESCRIPTION_AR')}
                            titleButton={t('TITLE_BUTTON_TALK_PROJECT')}
                            icons={icons}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <VideoIframe>
                        <IframeVideo title="youtube"
                            width="101%"
                            height="100%"
                            src="https://www.youtube.com/embed/aCfSloM6P_U"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></IframeVideo>
                    </VideoIframe>

                    <ContentReverse
                        title={t('TITLE_INDOOR_OUTDOOR')}
                        leftIcon={data.iphoneMockup.childImageSharp}
                        altDescription='3 mobiles with indoor augmented reality navigation in store'
                        titleButton={t('PLUS_INFOS')}
                        rightIcons={right}
                        urlFooter='/contact/'
                    />

                    <ContentReverse
                        title={t('TITLE_VISUAL_SEARCH')}
                        videoUrl='/images/products/wemap-navigation/search-augmented-reality-outdoor.mp4'
                        pictureLeftIphoneX={data.whiteMobile.childImageSharp}
                        altDescription='gif of augmented reality in search of pinpoints'
                        titleButton={t('TITLE_BUTTON_TRY_GET_STARTED')}
                        rightIcons={search}
                        urlFooter='/contact/'
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_AUGMENTED_REALITY')}
                        pictureRight={data.wemapWebApp.childImageSharp}
                        altDescription='mobile with connexion screen to media'
                        titleButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        urlFooter='/products/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_AROUND_YOU')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_CITYGUIDES')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_INDOOR_OUTDOOR')
                            },
                            {
                                icon: 'icon-coin-dollar',
                                text: t('DESCRIPTION_URBAN_GAMES')
                            }
                        ]}
                    />

                    <BannerPicture backgroundBanner={data.bannerAR.childImageSharp}
                        alt='Footer banner with augmented reality phone'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
    whiteMobile: file(
        relativePath: {eq: "products/wemap-ar/white_mobile_augmented-reality.png"}
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, width: 320, layout: FIXED)
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  iphoneMockup: file(relativePath: {eq: "products/wemap-ar/iphone-mockup.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  wemapWebApp: file(relativePath: {eq: "products/wemap-web/wemap_web_apps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
  bannerAR: file(
    relativePath: {eq: "products/wemap-ar/banner-wemap-augmented-reality.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
